body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
}

html, body, .app {
  width: 100%;
  height: 100%;
}

.app {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f8;
}

.arcade {
  position: relative;
}

.arcade-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.arcade iframe {
  color-scheme: light;
  border: none;
}

.redirect-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  box-sizing: border-box;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 80%);
  backdrop-filter: blur(4px);
  border: 1px solid #e5e5e5;
  border-radius: 10px;
}

.redirect-message-container h1,
.redirect-message-container p {
  text-align: center;
}

.redirect-message-container .spacer {
  height: 16%;
}

a.button {
  padding: 12px 16px;
  background-color: #006ee6;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

a.button:hover {
  background-color: #004da1;
}

h1 {
  margin: 0;
  font-size: 3.25rem;
  line-height: 4rem;
  font-weight: 400;
}

.subtitle {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
}

@media (max-width: 800px) {
  h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .subtitle {
    font-size: 1rem;
    line-height: 1.3rem;
  }
}